<template>
  <mescroll :up="mescrollUp" :down="mescrollDown">
    <div slot="scrollList" class="main_box">
      <div class="clean" :style="{ background: bgColor }">
        <div>
          <img :src="teamBanner.url" alt="">
        </div>
        <van-sticky>
          <div class="must_buy_tab">
            <van-tabs
              v-model="active"
              background="#2485e3"
              color="#fff"
              title-active-color="#fff"
              title-inactive-color="#fff"
            >
              <van-tab v-for="(item,index) in tabData" :key="index">
                <template #title>
                  <div @click="btn(item)">
                    <div class="must_statetime">{{ item.name }}</div>
                  </div>
                </template>
              </van-tab>
            </van-tabs>
          </div>
        </van-sticky>
        <skeleton v-if="showLoading" type="list" />
        <div v-else>
          <div class="jd_list_box">
            <div v-for="(item,index) in list" :key="index" class="jd_list" @click="btnLink(item)">
              <div class="jd_list_ovs">
                <imgLoading class="jd_list_img" :src="item.goodCover" />
                <div class="sale_num" />
              </div>
              <div class="jd_list_son">
                <div class="jd_name">
                  <span>{{ item.goodName }}</span>
                </div>
                <div class="jd_logo_ov">
                  <span class="jd_price">¥{{ item.goodVipPrice }}</span>
                  <span class="jd_original_price">¥{{ item.goodPrice }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mescroll>
</template>

<script>
import skeleton from '@/component/skeleton/index'
import { Lazyload, Tab, Tabs, Toast } from 'vant'
import Vue from 'vue'

Vue.use(Tab).use(Tabs).use(Toast).use(Lazyload)
import imgLoading from '@/component/imgLoading'
import { getActivityCategoryInfo, newActivityList } from '@/services/api'
import mescroll from '@/component/fresh_scroll/mescroll'

export default {
  name: 'Clean',
  components: {
    imgLoading,
    mescroll,
    skeleton
  },
  data() {
    return {
      showLoading: false,
      active: '',
      teamBanner: '',
      tabData: '',
      list: '',
      bgColor: '',
      id: this.$route.query.id,
      categoryId: 153,
      mescrollDown: {
        use: true,
        top: '0px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }
    }
  },
  mounted() {
    this.getCleanList()
    this.getTabList()
  },
  methods: {
    btn: function(t) {
      this.getCleanList()
      // this.categoryId=t.id
      if (this.categoryId !== t.id) {
        this.categoryId = t.id
        this.list = [] // 在这里手动置空列表,可显示加载中的请求进度
        this.mescroll.resetUpScroll() // 刷新列表数据
      }
    },
    // tab
    getTabList: function() {
      const data = {
        id: this.id
      }
      getActivityCategoryInfo(data).then(res => {
        if (Number(res.code) === 200) {
          this.teamBanner = res.data.activityInfo.backGround
          this.tabData = res.data.categoryInfo
          this.bgColor = res.data.activityInfo.backColor
        }
      })
    },
    // list
    getCleanList: function() {
      this.showLoading = true
      const data = {
        uid: window.localStorage.getItem('uid'),
        id: this.id,
        categoryId: this.categoryId,
        page: 1,
        pageSize: 10
      }
      newActivityList(data).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data[0].goodsInfo
          this.showLoading = false
        }
      })
    },

    mescrollInit(data) {
      this.mescroll = data
    },
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const data = {
        uid: window.localStorage.getItem('uid'),
        id: this.id,
        categoryId: this.categoryId,
        page: pageNum,
        pageSize: pageSize
      }
      newActivityList(data).then(res => {
        if (Number(res.code) === 200) {
          successCallback(res.data[0].goodsInfo)
        }
      })
    },
    btnLink: function(item) {
      const obj = {
        'product_type': 1, // 暂时借口没有返回 这个字段 product_type
        'goodId': item.goodId,
        'skuId': item.skuId //
      }
      this.$store.dispatch('godetail', obj)
    }
  }

}
</script>

<style scoped lang="less">
.clean {
  img {
    width: 100%;
  }

  .jd_list_box {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    box-sizing: border-box;
    // background: #f6f6f6;
    overflow: hidden;

    .jd_list {
      width: 169px;
      height: 260px;
      background: #fff;
      margin-right: 12px;
      float: left;
      margin-bottom: 6px;
      margin-top: 6px;
      border-radius: 6px;
      overflow: hidden;
    }

    .jd_list:nth-child(2n) {
      margin-right: 0px;
    }

    .jd_list_ovs {
      position: relative;

      .jd_list_img {
        width: 169px;
        height: 169px;
        overflow: hidden;
      }

      .sale_num {
        width: 100%;
        height: 16%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(97, 96, 96, 0.7));
      }

      .sale_num_style {
        position: absolute;
        right: 12px;
        bottom: 6px;
        color: #fff;
        font-size: 14px;
      }
    }

    .jd_list_son {
      padding: 0 7px;
      box-sizing: border-box;
      text-align: left;
      margin-top: 14px;

      .jd_name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;

        .jd_logo {
          background: #ff001c;
          color: #fff;
          border-radius: 3px;
          padding: 0px 1px;
          font-weight: bold;
          box-sizing: border-box;
          font-size: 8px;
          margin-right: 3px;
        }
      }

      .jd_logo_ov {
        margin-top: 10px;

        .jd_price {
          color: #ff001c;
          font-size: 16px;
        }

        .jd_original_price {
          text-decoration: line-through;
          color: #bbb;
          font-size: 12px;
          margin-left: 2px;
        }
      }

      .jd_logo_bj {
        margin-top: 6px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;
        font-size: 10px;
      }
    }
  }
}

</style>
